import React from "react"
import PageTemplate from '../components/pageTemplate'
import yoghurts from '../data/organic-coconut'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'
import certifiedOrganic from '../assets/images/certified-organic.jpg'

export default ({data}) => (
  <PageTemplate title="Organic Coconut Yoghurt" img={data.fileName}>
    <div className="text-center">
      <p>Not a fan of dairy? You can now be a part of the Raybek Foods family too with our Dairy Free, Organically certified Coconut yoghurt. </p>
    </div>
    <div className="d-flex align-items-center flex-column mb-3">
      <ul>
        <li>Free from preservatives</li>
        <li>Dairy Free</li>
        <li>Vegan</li>
        <li>100% Australian owned and operated</li>
        <li>Live Pro biotic</li>
        <li>Organic certified</li>
      </ul>
    </div>
    <ProductDisplay products={yoghurts}/>
    <div className="text-center">
      <img src={certifiedOrganic} width="200px"/>
    </div>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
